@import "../../../../vendor/css/variables-canusa.scss";

.item-select {
	margin: $header_mobile_height + 1rem 1rem 2rem;

	overflow: hidden;
	padding-bottom: 2rem;

	@media only screen and (min-width: $breakpoint_tablet_from) {
		margin: $header_height + 1rem 1rem 2rem;
	}

	@media only screen and (min-width: $breakpoint_desktop_from) {
		margin: $header_height + 1rem 1rem 2rem;
		margin: unset;
	}

	&__head {
		margin-bottom: 2rem;
	}

	&__label {
		margin-bottom: 0.5rem;

		@media screen and (min-width: $breakpoint_tablet_from) {
			margin-right: 1.75rem;
		}
	}

	&__date {
		position: relative;
		margin-bottom: 0.75rem;

		&:after {
			content: '';
			background-color: $color-grey-100;
			width: 10%;
			height: 1px;
			margin-top: 1rem;
			margin-bottom: 1rem;
		}
	}

	&__disclaimer-wrapper {
		display: flex;
		flex-flow: column nowrap;
		@media only screen and (min-width: $breakpoint_desktop_from) {
			flex-direction: row;
			align-items: center;
		}
	}

	&__disclaimer-text {
		display: block;
		margin-top: 0.5rem;
		@media only screen and (min-width: $breakpoint_desktop_from) {
			display: inline-flex;
			margin-top: unset;
			margin-left: 0.5rem;
			margin-bottom: 0.5rem;
		}
	}

	&__disclaimer-icon {
		padding: .25rem;
		.badge__icon {
			width: 1.2rem;
			height: 1.2rem;
		}
	}


	&__event {

		@media only screen and (min-width: $breakpoint_desktop_from) {

			padding-right: 1rem;
			margin-right: 1rem;
			border-right: 1px solid $color-grey-100;
		}
	}

	&__avail {
		color: $color-grey-400;
		margin-bottom: 1.5rem
	}

	&__options-section {
		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}


	&__contact {
		margin-top: 1rem;
	}

	&__checkbox {

	}
}
