@import "../../../vendor/css/variables-canusa.scss";

$block: '.header';

#{$block} {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	background-color: $color-white;
	color: white;
	z-index: 999;
	height: $header_mobile_height;

	@media only screen and (min-width: $breakpoint_large_desktop_from) {
		height: $header_height;
	}

	box-shadow: $navigation_shadow;


	&--stick-to-top {
		position: sticky;
		top: 0;
	}

	&__max-vp-wrapper {
		width: 100%;
		display: flex;
		justify-content: space-between;
		max-width: $content_max_width;
		margin: 0 auto;
		align-items: center;
	}


	&__logo {
		&-icon {
			font-size: 2.2rem;
			margin-left: -10px;

			img {
				height: $header_mobile_height;
				width: auto;
			}

			@media screen and (min-width: $breakpoint_large_desktop_from) {
				display: none;
			}
		}

		&-image {
			display: none;

			@media screen and (min-width: $breakpoint_large_desktop_from) {
				display: inherit;
				img {
					height: $header_height;
					width: auto;
					background: linear-gradient(90deg, $color-white 0%, $color-grey-300 60%, $color-white 10%);
				}
			}
		}
	}


	& #{$block}__button {
		// exception for mobile view
		@media only screen and (max-width: $breakpoint_tablet_to) {
			background-color: transparent;
			padding: .25rem;
			border: none;

			&:hover {
				background-color: transparent;
			}

			.button__label {
				display: none;
			}

			.button__icon {
				font-size: 1.5rem;
				width: 1.5rem;
				height: 1.5rem;
				color: $color-grey-900;
			}
		}
	}


	&__interaction {
		padding: 0 2rem 0 0;
		@media only screen and (max-width: $breakpoint_mobile_small) {
			padding: 0;
		}
	}

	&__print-logo-image {
		visibility: hidden;
		width: 0;
	}

	//--------------------------------------------------- PRINT ONLY ---------------------------------------------------

	@media print {
		background: none;
		box-shadow: none;

		padding: 0;
		margin-bottom: 2rem;

		.no-print {
			display: none;
		}


		&__interaction {
			display: none;
		}

		&__max-vp-wrapper {
			margin-bottom: 0;
			padding: 0;
			background-color: transparent;
			color: inherit;
		}

		&__logo-image {
			display: none;
		}

		&__print-logo-image {
			visibility: visible;
			display: block !important;
			width: 380px;
			background-color: $color-grey-900;
			-webkit-print-color-adjust: exact;
			border: 1px solid $color-grey-900;
		}

		&__notifications, &__button, &__logo-icon, &__offer {
			display: none !important;
		}

	}
}
