@import "../../../../vendor/css/variables-canusa.scss";

$icon-size: 1.5rem;
$block: '.item-header';
#{$block} {
	display: flex;
	transition: all $transition_time $transition_ease;
	transform: translateZ(0);

	&__type-icon {
		font-size: 1.5rem;
		background-color: $color-gold-250;
		color: $color-gold-700;

		transition: all $transition_time $transition_ease;
		transform: translateZ(0);

		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0.5rem;

		@media print {
			border-right: 1px solid $color-grey-100;
		}
	}

	&__extra {
		#{$block}__type-icon {
			color: $color-red-600;
		}
	}

	&__title {
		display: flex;
		align-items: center;
	}

	&__origin-hint {
		color: $color-yellow-700;
		margin-right: 0.5rem;
	}

	&__collapsible {
		min-width: 1rem;
		margin-left: 0.875rem;
		pointer-events: none;
	}

	&__badge {
		margin-left: 1rem;
	}

	&__link {
		margin-top: 2px;
	}

	&__horizontal-line {
		border-bottom: 1px solid $color-grey-100;
		width: 100%;
		margin: 0.5rem 0;

		@media only screen and (min-width: $breakpoint_desktop_from) {
			display: none;
		}
	}

	/*
	############################################################
	HIGHLIGHT START
	############################################################
	 */
	.item--highlight & {

		@media only screen and (min-width: $breakpoint_desktop_from) {

			&__type-icon {
				background-color: $color-gold-700;
				color: $color-white;
			}

			&__extra {
				#{$block}__type-icon {
					color: $color-red-600;
				}
			}

			&__extra-badge {
				color: $color-white;
				background-color: $color-red-600;
			}

			&:hover {
				background-color: $color-gold-700;
				color: $color-white;
			}

			&__badge {
				.badge {
					color: $color-gold-250;
					border-color: $color-gold-250;
				}
			}


			&__date, &__duration, &__location {
				color: $color-gold-250;
			}

			background-color: $color-gold-700;
			color: $color-white;
		}


	}

	/*
	############################################################
	HIGHLIGHT END
	############################################################
	 */

	/*
	#################################################
	POSITIONING START
	#################################################
	*/

	&__grid {
		display: flex;
		flex-flow: column nowrap;
		min-height: 5rem;

		padding: 0.875rem;
		width: 100%;
		border-left: 1px solid $color-white;
		pointer-events: none;
	}

	&__clickarea {
		pointer-events: all;
		z-index: -1;
	}

	&__top {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__top-left {
		display: flex;
		flex-flow: row;
		flex-wrap: wrap;
		gap: 1rem;
		justify-content: flex-end;
		align-items: center;
	}

	&__top-right {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		@media print {
			display: none;
		}
	}

	&__middle {
		display: flex;
		width: 100%;
		height: 100%;
		justify-content: space-between;
		align-items: center;
		pointer-events: none;
	}

	&__middle-left {
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;
	}

	&__middle-right {
		display: flex;
		justify-content: space-between;
	}

	&__bottom {
		padding-top: 0.5rem;
		display: flex;
		justify-content: space-between;
	}

	&__bottom-left {
		display: flex;
		flex-flow: row;
		flex-wrap: wrap;
		gap: 0.25rem;
	}

	&__bottom-right {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	&_bottom-right {
		align-self: end;
		text-wrap: nowrap;
	}


	.item-header-optionals__select {
		pointer-events: all;
	}

	.item-header__origin-hint {
		margin-left: .25rem;
	}
	/*
	#################################################
	POSITIONING END
	#################################################
	*/

	//--------------------------------------------------- PRINT ONLY ---------------------------------------------------

	@media print {
		page-break-inside: avoid;

		&__type-icon {
			border: none
		}

		&__collapsible,
		&__note,
		&__link,
		&__select,
		&__type-icon:empty {
			display: none;
		}
	}
}
