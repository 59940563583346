@import "../../../../vendor/css/variables-canusa.scss";

.item-details {
	max-height: 0;
	transition: max-height .5s cubic-bezier(0, 1, 0, 1);

	@media only screen and (min-width: $breakpoint_desktop_from) {
		overflow: hidden;
	}

	&__fallback-spacing {
		margin-top: $modal_header_height;
	}

	&__content {
		padding: 1rem;
		animation-duration: .5s;
		animation-iteration-count: 1;
		animation-timing-function: linear;
		animation-fill-mode: forwards;
		animation-name: fadeIn;

		&--print {
			display: none;
			animation: none;
			border-top: 1px solid $color-grey-100;
		}

		@media screen and (min-width: $breakpoint_desktop_from) {
			padding: 1.5rem;
		}
	}

	&__navigation {
		margin: 0;
		text-align: center;

		:only-child {
			text-align: left;
		}

		@media screen and (min-width: $breakpoint_desktop_from) {
			margin: 0 1.5rem;
		}
	}

	&__tab {
		position: relative;
		transition: color $transition_hover;
		padding: 1rem;
		overflow: hidden;
		cursor: pointer;

		&:hover {
			color: $color-gold-700;
		}

		input:checked ~ & {
			color: $color-gold-700;
			pointer-events: none;
			cursor: default;

			&:after {
				width: 100%;
				bottom: 0;
			}
		}

		&:after, &:before {
			display: block;
			position: absolute;
			bottom: -1px;
			left: 50%;
			transform: translateX(-50%);
			content: '';
			height: 2px;
		}

		&:before {
			width: 100%;
			background-color: $color-grey-100;
		}

		&:after {
			width: 100%;
			bottom: -5px;
			background-color: $color-gold-700;
			transition: bottom .3s ease;
		}
	}

	&__section {
		a {
			display: flex;
		}

		&:not(:last-child) {
			margin-bottom: 1rem;
		}
	}

	&__itinerary {
		.ts-bold {
			@include h4();
			text-transform: uppercase;
			padding: 1rem 0.5rem 0.8rem;
			background-color: #f0f0f0;
			border-bottom: dotted 2px #c6c2b4;
		}

		table {
			width: 100%;

			thead {
				th {
					padding: 1rem 0.5rem 0.8rem;
					background-color: #f0f0f0;

				}
			}

			tbody {
				td {
					padding: 0.25rem 0.5rem;
				}
			}

			.flight-info {
				border-top: dotted 2px #c6c2b4;
				text-transform: uppercase;
				font-weight: 500;
				border-collapse: collapse;
				padding-top: 1rem;
			}

			.last-item {
				padding-bottom: 1.5rem;
			}
		}
	}

	&__single-image-wrapper {
		display: flex;
		flex-flow: column nowrap;

		@media only screen and (min-width: $breakpoint_tablet_from) {
			flex-flow: row nowrap;
		}
	}

	&__single-image {
		.gallery {
			border: 1px solid $color-grey-100;
			@media only screen and (min-width: $breakpoint_tablet_from) {
				width: 281px;
			}
		}
	}

	&__single-image-text {
		@media only screen and (min-width: $breakpoint_tablet_from) {
			padding-left: 1rem;
		}
	}

	&__external-highlight {
		display: inline-block;
		background-color: $color-grey-100;
		border-radius: 4px;
		padding: 1rem;
	}

	&__print-label {
		font-weight: bold;
		margin-top: 2rem;
	}

	//--------------------------------------------------- PRINT ONLY ---------------------------------------------------

	@media print {
		max-height: unset;
		display: none;
		height: 100%;

		.no-print {
			display: none;
		}

		&__navigation {
			display: none !important;
		}

		&__single-image {
			display: none;
		}

		&__content {
			display: none;

			&--print {
				display: block;
				transition: unset;
				animation-duration: 0s;
				animation: unset;

				max-height: unset;
				min-height: 100%;
				height: 100%;
			}
		}
		.print-content & {
			display: block;
		}
	}
}
