@import "../../../../vendor/css/variables-canusa.scss";

$block: '.checkout-contact';

#{$block} {
	&__gender {
		width: 100px;
	}

	&__zip {
		width: 120px;
	}
}

.debt-collection {
	&__iban {
		margin-top: 1rem;
	}
}

.renters, .debt-collection {
	margin-top: 3rem;

	&__box {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		margin-bottom: 1.5rem;
	}

	&__header {
		padding: 1.5rem 0;
		>.h3{
			margin-bottom: .5rem;
		}
	}

	&__item {
		display: flex;
		flex-flow: column nowrap;
		padding: 0.5rem 0 1.5rem;
		border-radius: .5rem;

		margin-bottom: 0.5rem;
		border-bottom: 1px $color-gold-250 solid;
		@media screen and (min-width: $breakpoint_tablet_from), print {
			flex-flow: row nowrap;
			padding: 1.5rem;
			margin-bottom: 0;
			justify-content: space-between;
			align-items: center;
			background-color: $color-grey-100;
		}
	}

	&__title {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		margin-bottom: 1rem;
		justify-content: space-between;
		@media screen and (min-width: $breakpoint_tablet_from), print {
			align-items: center;
			margin-bottom: 0;
		}
	}

	&__label {
		@media screen and (min-width: $breakpoint_tablet_from), print {
			flex-basis: auto;
			margin-left: 0;
		}
	}

	&__date-wrapper {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		width: 130px;
	}

	&__date {
		font-weight: bold;
		margin: 0 1rem;
	}

	&__select {
		min-width: 200px;
	}
}
