@import "../../../vendor/css/variables-canusa.scss";
@import "../../../vendor/css/common-styles.scss";

.da-login-wrapper {

	&__headline {
		text-align: left;
	}

	&__copy {
		margin: 1rem 0 1rem 0;
		text-align: left;
	}

	&__icon {
		padding: 1rem;
		display: flex;
		justify-content: center;

		> svg {
			width: 5rem;
			height: 5rem;
		}
	}
}
