@import "../../../../../vendor/css/variables-canusa";
.static-map{
	position: relative;
	margin-top: $header_mobile_height;
	&.google-map-fallback {
		width: 100%;
		overflow: hidden;
	}

	@media only screen and (min-width: 768px){
		margin-top: $header_height;
	}

	img{
		width: 100%;
	}

	&__pin{
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;

		display: flex;
		justify-content: center;
		align-items: center;

		img{
			height: 5rem;
			left: 50%;
			margin-top: -4rem
		}
	}
}
