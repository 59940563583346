@import "../../../vendor/css/variables-canusa.scss";

#checkout-modal {
	.modal__content {
		padding-top: 0.75rem;
		max-width: 744px;
		width: 100%;
	}
}

$block: '.checkout';

#{$block} {
	>h1 {
		margin-bottom: .75rem;
	}
}
