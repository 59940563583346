@import "../../../../vendor/css/variables-canusa.scss";

$block: '.checkout-summary';

$firstColumnWidth: 75%;
$secondColumnWidth: 25%;

#{$block} {

	&__label {
		margin-bottom: 1rem;
		@media screen and (min-width: $breakpoint_tablet_from), print {
			flex-basis: $secondColumnWidth;
			&:first-of-type {
				flex-basis: $firstColumnWidth;
			}
		}
	}

	&__head {
		display: flex;
	}

	&__body {
		display: flex;
	}

	&__box {
		position: relative;
		background-color: $color-grey-100;
		padding: 1.5rem;
		border-radius: .5rem;
	}

	&__edit {
		position: absolute;
		padding: 0.5rem;
		cursor: pointer;
		top: 0.5rem;
		right: 0.5rem;
		transition: opacity $transition_time ease;

		&:hover {
			opacity: 0.6;
		}
	}

	&__participants {
		margin: 2rem 0 1rem;
	}

	&__debt-collection {
		margin-top: .5rem;
	}

	&-participants {
		display: flex;
		justify-content: space-between;
		padding: 1rem 0;
		border-bottom: 1px $color-gold-250 solid;

		&:first-child {
			margin-top: 0;
			padding-top: 0;
		}

		&:last-child {
			border-bottom: none;
		}

		&__list {
			display: flex;
			flex-flow: column nowrap;
			width: 100%;
		}

		&__birthdate-label {
			display: none;
			@media screen and (min-width: $breakpoint_tablet_from), print {
				display: flex;
				flex-basis: $secondColumnWidth;
			}
		}

		&__name {
			display: flex;
			@media screen and (min-width: $breakpoint_tablet_from), print {
				flex-basis: $firstColumnWidth;
			}

			&--inline {
				margin-left: 0.5rem;
			}
		}

		&__birthdate {
			display: none;
			@media screen and (min-width: $breakpoint_tablet_from), print {
				flex-basis: $secondColumnWidth;
				display: flex;
			}
		}

		&__disclaimer {
			margin-top: 1rem;
		}
	}

	&__contact {
		margin-bottom: 1.5rem;
		@media screen and (min-width: $breakpoint_tablet_from), print {
			margin-bottom: 0;
			flex-basis: $firstColumnWidth;
		}
	}

	&__address {
		@media screen and (min-width: $breakpoint_tablet_from), print {
			flex-basis: $secondColumnWidth;
		}
	}

	&__contact-info {
		#{$block}__body {
			display: flex;
			flex-flow: column nowrap;
			@media screen and (min-width: $breakpoint_tablet_from), print {
				flex-flow: row nowrap;
			}
		}
	}

	&__renter-list {
		display: flex;
		flex-flow: column nowrap;
		width: 100%;
	}

	&__renter-info {
		margin-top: 2rem;
	}

	&__renter-item {
		display: flex;
		flex-flow: column nowrap;
		@media screen and (min-width: $breakpoint_tablet_from), print {
			flex-flow: row nowrap;
			align-items: center;
		}

		padding-bottom: 0.5rem;
		margin-bottom: 0.5rem;
		border-bottom: 1px $color-gold-250 solid;

		&:last-child {
			border-bottom: none;
			padding-bottom: 0;
			margin-bottom: 0;
		}
	}

	&__vehicle-label {
		display: none;
		@media screen and (min-width: $breakpoint_tablet_from), print {
			display: flex;
		}
	}

	&__renter-name {
		flex-basis: $firstColumnWidth;
	}

	&__vehicle {
		flex-basis: $secondColumnWidth;
	}

	&__consent {
		display: inline-flex;
		align-items: center;
		width: 100%;
		// prevent jumping checkbox
		.tooltip-wrapper__children {
			display: flex;
		}

		@media screen and (min-width: $breakpoint_tablet_from), print {
			display: flex;
		}
	}

	&__checkbox-wrapper {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		margin-top: 1rem;
	}

	&__consent-label {
		display: inline;

		margin-left: 4px;

		@media screen and (min-width: $breakpoint_tablet_from), print {
			align-items: center;
			display: flex;
		}

		> .link + span {
			font-weight: normal;
			margin-left: 4px;
		}
	}

	&__booking-status {
		&> * {
			margin-bottom: 1rem;
		}
	}
	&__days-until {
		margin-top: 3rem;
	}

	.booking-immigration-info {
		padding-bottom: 1rem;
	}
}
